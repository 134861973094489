import state from "./moduleVehiclesColorState.js";
import mutations from "./moduleVehiclesColorMutations.js";
import actions from "./moduleVehiclesColorActions.js";
import getters from "./moduleVehiclesColorGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
