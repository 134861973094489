export default {

  ADD_VehiclesColor(state, item) {
    state.VehiclesColor.unshift(item);
  },

  SET_VehiclesColor(state, VehiclesColor) {
    state.VehiclesColors = VehiclesColor;
  },

  UPDATE_VehiclesColor(state, item) {
    const Index = state.VehiclesColors.findIndex(p => p.Id == item.Id);
    Object.assign(state.VehiclesColors[Index], item);
  },

  REMOVE_VehiclesColor(state, itemId) {
    const ItemIndex = state.VehiclesColors.findIndex(p => p.Id == itemId);
    state.VehiclesColors.splice(ItemIndex, 1);
  }

};
